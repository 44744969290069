/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

p {
    @apply font-mono;
}

body{
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html{
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.fill-available{
    height: 100vh;
    min-height: -webkit-fill-available;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
    @apply rounded-br-md bg-gray-800;
}

::-webkit-scrollbar-thumb {
    @apply rounded-br-md bg-gray-700 transition-all;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-600 transition-all;
}